/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
// import utils.css
@import "utils.scss";

/* Using Ionic-specific classes to ensure full coverage */

ion-content {
  --background: url("/assets/img/BG-MM-3.png") no-repeat center center / cover;
  // --background: url("../assets/bg-full.svg") no-repeat center/cover,
  //   linear-gradient(0deg, #bfa7d1 0%, #c0acd4 29%, #c4bcde 67%, #c7c7e5 84%);

  // Ensures the ability to position children absolutely within it
  position: relative;
  --padding-top: 56px !important;
  /* * {
    position: relative;
    z-index: 2; // Ensures content is above the SVG backgrounds
  }
  // Adding an SVG in the upper right corner
  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 50vw; // Adjust size as needed
    height: 50vh; // Adjust size as needed
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }

  // Upper right SVG
  &::after {
    background-image: url("../assets/bg-top-right.svg"); // Change path to your SVG
    top: -50;
    right: 0;
  }

  // Bottom left SVG
  /*&::before {
    background-image: url("../assets/bg-bottom-left.svg"); // Change path to your SVG
    bottom: 0;
    left: 0;
    background-position: bottom left;
  }*/
}

.wrapper {
  position: relative;
  height: 100%;

  .background-img {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  ion-content {
    z-index: 3;
    --background: transparent;
  }
}
ion-toolbar {
  --background: transparent;
  --border-color: transparent;
  margin-bottom: -76px;
  // padding-top: 40px !important;
  box-shadow: none;
  ion-title {
    margin-right: 48px;

    h1 {
      font-weight: 400;
      font-size: 1.3em;
      margin-top: 12px;
      background-color: rgba($color: #fff, $alpha: 0.25);
      width: fit-content;
      padding: 2px 8px;
      border-radius: 20px;
    }
  }
}
ion-button {
  text-transform: none;
}
ion-button[size="large"] {
  ion-icon {
    font-size: 1.9em;
  }
}

//ionbutton large
h1,
h2,
h3,
h4,
h5,
h6,
p,
* {
  font-family: "Comic Gecko Pro", sans-serif !important;
}

.pleca {
  position: absolute;
  height: 100%;
  z-index: -1;
  width: 100%;
  top: 0px;
}

ion-modal.modal-full-screen::part(content) {
  --height: 100%;
  --width: 100%;
  border-radius: 0px;
}
