.item-transparent {
  --background: transparent;
}
.input-container {
  --background: var(--ion-color-light);
  --min-height: 20px;
  margin-bottom: 15px;
  border-radius: 25px;
}
.content-infocard {
  ion-grid,
  ion-row {
    height: 100%;
  }

  ion-row {
    display: flex;
    align-items: end;
  }
  ion-card {
    --background: rgba(var(--ion-color-light-rgb), 0.85);

    border-radius: 25px;
    padding: 16px 16px;

    ion-item {
      --background: transparent;
    }
    h2 {
      margin: 0;
      padding: 0;
    }
    h4 {
      margin: 0;
      padding: 0;
      font-weight: 300;
      font-size: 1.1em;
    }
    p {
      font-size: 1.2em;
    }
  }
}
ion-input {
  --placeholder-color: red;
  --placeholder-opacity: 1;
}
