/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~@ionic/angular/css/ionic.bundle.css";

// Theme customization
@import "theme/theme";
