/*
 * Application global variables.
 */

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/* src/global.scss */
@font-face {
  font-family: "Comic Gecko Pro";
  src: url("/assets/fonts/comic-gecko-pro.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Apply globally */
body {
  font-family: "Comic Gecko Pro", sans-serif !important;
}

input::placeholder {
  font-family: "Comic Gecko Pro";
}

input:not(:placeholder-shown) {
  font-family: Arial, sans-serif !important;
}

:root {
  --ion-color-primary: #644b78;
  --ion-color-primary-rgb: 100, 75, 120;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #503c60;
  --ion-color-primary-tint: #836f93;

  --ion-color-stable: #c7c7e5;
  --ion-color-stable-rgb: 199, 199, 229;
  --ion-color-stable-contrast: #644b78;
  --ion-color-stable-contrast-rgb: 100, 75, 120;
  --ion-color-stable-shade: #b1b1d1;
  --ion-color-stable-tint: #d0d0eb;

  --ion-color-secondary: #e6358a;
  --ion-color-secondary-rgb: 230, 53, 138;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ca2f79;
  --ion-color-secondary-tint: #e94996;

  --ion-color-tertiary: #36a8df;
  --ion-color-tertiary-rgb: 54, 168, 223;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3094c4;
  --ion-color-tertiary-tint: #4ab1e2;

  --ion-color-success: #358f5e;
  --ion-color-success-rgb: 53, 143, 94;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2f7e53;
  --ion-color-success-tint: #499a6e;

  --ion-color-warning: #f3f453;
  --ion-color-warning-rgb: 243, 244, 83;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d6d749;
  --ion-color-warning-tint: #f4f564;

  --ion-color-danger: #ea3c4f;
  --ion-color-danger-rgb: 234, 60, 79;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ce3546;
  --ion-color-danger-tint: #ec5061;

  --ion-color-light: #f8f8f8;
  --ion-color-light-rgb: 248, 248, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dadada;
  --ion-color-light-tint: #f9f9f9;

  --ion-color-medium: #6d6b6c;
  --ion-color-medium-rgb: 109, 107, 108;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #605e5f;
  --ion-color-medium-tint: #7c7a7b;

  --ion-color-dark: #373536;
  --ion-color-dark-rgb: 55, 53, 54;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #302f30;
  --ion-color-dark-tint: #4b494a;

  --ion-color-google: #db4437;
  --ion-color-google-rgb: 219, 68, 55;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #c13b31;
  --ion-color-google-tint: #dc5248;

  --ion-color-facebook: #4267b2;
  --ion-color-facebook-rgb: 66, 103, 178;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #395399;
  --ion-color-facebook-tint: #5071c2;

  --ion-color-apple: #000000;
  --ion-color-apple-rgb: 0, 0, 0;
  --ion-color-apple-contrast: #ffffff;
  --ion-color-apple-contrast-rgb: 255, 255, 255;
  --ion-color-apple-shade: #000000;
  --ion-color-apple-tint: #1a1a1a;

  --ion-background-color: #f8f8f8; /* New main background */
  --ion-background-color-rgb: 248, 248, 248;
  --ion-background-color-step-50: #f2f2f2;
  --ion-background-color-step-100: #ececec;
  --ion-background-color-step-150: #e6e6e6;
  --ion-background-color-step-200: #e0e0e0;
  --ion-background-color-step-250: #dadada;
  --ion-background-color-step-300: #d4d4d4;
  --ion-background-color-step-350: #cecece;
  --ion-background-color-step-400: #c8c8c8;
  --ion-background-color-step-450: #c2c2c2;
  --ion-background-color-step-500: #bcbcbc;
  --ion-background-color-step-550: #b6b6b6;
  --ion-background-color-step-600: #b0b0b0;
  --ion-background-color-step-650: #aaaaaa;
  --ion-background-color-step-700: #a4a4a4;
  --ion-background-color-step-750: #9e9e9e;
  --ion-background-color-step-800: #989898;
  --ion-background-color-step-850: #929292;
  --ion-background-color-step-900: #8c8c8c;
  --ion-background-color-step-950: #868686;
  --ion-background-color-step-1000: #644b78;

  /** content margin and padding **/
  --ion-content-margin: 16px;
  --ion-content-padding: 16px;
}
/* Google Color Utility */
.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

/* Facebook Color Utility */
.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

/* Apple Color Utility */
.ion-color-apple {
  --ion-color-base: var(--ion-color-apple);
  --ion-color-base-rgb: var(--ion-color-apple-rgb);
  --ion-color-contrast: var(--ion-color-apple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-apple-contrast-rgb);
  --ion-color-shade: var(--ion-color-apple-shade);
  --ion-color-tint: var(--ion-color-apple-tint);
}

/* Stable Color Utility */
.ion-color-stable {
  --ion-color-base: var(--ion-color-stable);
  --ion-color-base-rgb: var(--ion-color-stable-rgb);
  --ion-color-contrast: var(--ion-color-stable-contrast);
  --ion-color-contrast-rgb: var(--ion-color-stable-contrast-rgb);
  --ion-color-shade: var(--ion-color-stable-shade);
  --ion-color-tint: var(--ion-color-stable-tint);
}
